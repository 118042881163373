import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Icon, Avatar } from "../../shared-components";
import { PurchaseConfirmationModal } from "./PurchaseConfirmationModal";
import {
  simpleShorten,
  getIndexForPartId,
  injectTrans,
  scrollToRef
} from "../../helpers";
import { toggleModal } from "../../shared-components/modals/actions";
import CoinIcon from "./CoinIcon";
import Wallet from "./Wallet";

import {
  fetchWalletBalance,
  startPaidContentPurchase,
  markPaidContentPurchaseSuccessful,
  finishPaidContentPurchase
} from "./actions";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import isHybeStory from "../../helpers/isHybeStory";

class StoryPartPaywallUI extends React.Component {
  static propTypes = {
    blockedPart: PropTypes.shape({
      price: PropTypes.number,
      id: PropTypes.number.isRequired,
      blockedMessage: PropTypes.string,
      isBonusPart: PropTypes.bool
    }),
    isSubscriptionCoinsTestGroup: PropTypes.bool,
    currentPartIsBlocked: PropTypes.bool.isRequired,
    storyPrice: PropTypes.number,
    isDesktop: PropTypes.bool.isRequired,
    amount: PropTypes.number.isRequired,
    group: PropTypes.shape({
      user: PropTypes.shape({
        username: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }),
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      completed: PropTypes.bool
    }),
    remainingBlockedParts: PropTypes.number.isRequired,
    buyPaidContent: PropTypes.func,
    fetchWalletBalance: PropTypes.func.isRequired,
    startPaidContentPurchase: PropTypes.func.isRequired,
    markPaidContentPurchaseSuccessful: PropTypes.func.isRequired,
    finishPaidContentPurchase: PropTypes.func.isRequired,
    purchase: PropTypes.shape({
      isPurchasing: PropTypes.bool.isRequired,
      amountSpent: PropTypes.number.isRequired,
      buyingPart: PropTypes.bool.isRequired
    }).isRequired,
    trans: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    showStoryPaywallModal: PropTypes.func,
    onBuyCoins: PropTypes.func
  };

  componentDidMount() {
    const { blockedPart, amount, group } = this.props;

    if (blockedPart) {
      const indexOfBlockedPart = getIndexForPartId(blockedPart.id, group);
      window.te.push("event", "paywall", null, null, "view", {
        page: "reading",
        storyid: group.id,
        partid: blockedPart.id,
        starting_balance: amount,
        part_index: indexOfBlockedPart
      });
    }
  }

  onPurchase = (price, storyId, partId, isConfirmed = false) => {
    const {
      buyPaidContent,
      blockedPart,
      fetchWalletBalance,
      startPaidContentPurchase,
      markPaidContentPurchaseSuccessful,
      finishPaidContentPurchase,
      trans,
      isDesktop,
      group,
      toggleModal,
      amount,
      onBuyCoins,
      currentPartIsBlocked
    } = this.props;

    const canBuyContent = price <= amount;
    if (!canBuyContent) {
      window.te.push("event", "paywall", "purchase", null, "failed", {
        page: "reading",
        storyid: storyId,
        partid: partId,
        cost: price,
        starting_balance: amount,
        part_index: partIndex
      });
      onBuyCoins();
      return;
    }

    if (!isDesktop) {
      // Wallet isn't always visible on mobile, so scroll to guarantee it's in view for the animation
      scrollToRef(this.paywallRef, -25);
    }

    const onCancelPurchase = () =>
      window.te.push("event", "paywall", "purchase", null, "cancelled", {
        page: "reading",
        storyid: storyId,
        partid: partId,
        cost: price,
        part_index: partIndex,
        content_type: partId ? "part" : "story"
      });

    const partIndex = getIndexForPartId(blockedPart.id, group);

    // Confirmation dialog supports confirming either part or story
    // purchase, but is just shown for story purchase for now.
    const isUnconfirmedStoryPurchase = partId === undefined && !isConfirmed;
    if (isUnconfirmedStoryPurchase) {
      toggleModal({
        component: ({ toggleModal }) => (
          <PurchaseConfirmationModal
            onPurchase={() => {
              this.onPurchase(price, storyId, partId, true);
            }}
            onCancelPurchase={onCancelPurchase}
            toggleModal={toggleModal}
            currentPartIsBlocked={currentPartIsBlocked}
            isPurchasingStory={!partId}
            price={price}
          />
        ),
        className: "purchase-confirmation",
        onCloseModal: onCancelPurchase
      });
      return;
    }

    Promise.all([
      buyPaidContent(price, storyId, partId, partIndex),
      startPaidContentPurchase(price, partId)
    ])
      .then(() => {
        fetchWalletBalance(wattpad.user.username, true);
        return markPaidContentPurchaseSuccessful();
      })
      .then(finishPaidContentPurchase)
      .then(() => {
        // Can't use nextPart.url because it's an absolute path
        const nextPartUrl = `/${blockedPart.id}`;
        window.app.router.navigate(nextPartUrl, { trigger: true });

        // Delay toast until after the page has navigated
        // Ideally, would be able to run this async after nav is finished - but can't with Backbone
        setTimeout(() => {
          const {
            group: { user }
          } = this.props;

          const toastContent = ReactDOMServer.renderToString(
            <SuccessToast trans={trans} user={user} />
          );

          window.wattpad.utils.showToast(toastContent, {
            dismissAfterDuration: 6000
          });
        }, 700);
      })
      .catch(function() {
        finishPaidContentPurchase();
      });
  };

  renderAuthorDetails = () => {
    const { isDesktop, group, trans } = this.props;
    const { user: storyAuthor, description } = group;
    const { username, name, avatar } = storyAuthor;

    if (isDesktop) {
      return (
        <div className="author-info desktop">
          <a className="on-navigate" href={`/user/${username}`}>
            <div className="avatar avatar-lg center-block">
              <Avatar avatar={avatar} username={username} />
            </div>
          </a>
          <div className="info">
            <a className="on-navigate" href={`/user/${username}`}>
              <span
                className="center-block"
                dangerouslySetInnerHTML={{
                  /* prettier-ignore */ __html: trans("by <strong>%s</strong>", name)
                }}
              />
            </a>
            <a className="on-navigate" href={`/user/${username}`}>
              <div>{`@${username}`}</div>
            </a>
            <div className="description">{simpleShorten(description, 70)}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="author-info mobile">
          <div className="avatar avatar-sm center-block">
            <Avatar avatar={avatar} username={username} />
          </div>
          <span
            dangerouslySetInnerHTML={{
              /* prettier-ignore */ __html: trans('by <a href=%s class="on-navigate">%s</a>', `/user/${username}`, username)
            }}
          />
        </div>
      );
    }
  };

  getConditionalCopy = (
    currentPartIsBlocked,
    allowStoryPurchase,
    blockedMessage,
    isBonusPart,
    isSubscriptionCoinsTestGroup,
    trans
  ) => {
    const buyPartCopy = currentPartIsBlocked
      ? !isBonusPart
        ? isSubscriptionCoinsTestGroup
          ? trans("Buy this part")
          : trans("Unlock this part")
        : trans("Read it now")
      : isSubscriptionCoinsTestGroup
        ? trans("Buy next part")
        : trans("Unlock next part");

    let unlockCopy;
    if (currentPartIsBlocked) {
      if (allowStoryPurchase) {
        unlockCopy = isSubscriptionCoinsTestGroup
          ? trans("Buy this story part or the entire story.")
          : trans("Unlock this story part or the entire story.");
      } else {
        unlockCopy = isSubscriptionCoinsTestGroup
          ? trans("Buy this story part.")
          : trans("Unlock this story part.");
      }
    } else {
      if (allowStoryPurchase) {
        unlockCopy = isSubscriptionCoinsTestGroup
          ? trans("Buy a new story part or the entire story.")
          : trans("Unlock a new story part or the entire story.");
      } else {
        unlockCopy = isSubscriptionCoinsTestGroup
          ? trans("Buy a new story part.")
          : trans("Unlock a new story part.");
      }
    }

    const earnCopy = allowStoryPurchase
      ? trans(
          "Either way, your Coins help writers earn money for the stories you love."
        )
      : trans("Your Coins help writers earn money for the stories you love.");

    const bonusChapterUnlockCopy = isBonusPart
      ? trans("Unlock this part to continue reading instantly.")
      : null;

    const supportCtaCopy =
      blockedMessage || bonusChapterUnlockCopy || `${unlockCopy} ${earnCopy}`;

    return { buyPartCopy, supportCtaCopy };
  };

  render() {
    const {
      blockedPart,
      currentPartIsBlocked,
      group,
      isSubscriptionCoinsTestGroup,
      storyPrice,
      remainingBlockedParts,
      purchase,
      isDesktop,
      trans,
      showStoryPaywallModal,
      onBuyCoins
    } = this.props;
    const {
      user: storyAuthor,
      id: storyId,
      completed: storyIsComplete
    } = group;
    const { username, name } = storyAuthor;
    const {
      price: partPrice,
      id: partId,
      blockedMessage,
      isBonusPart
    } = blockedPart;
    const { isPurchasing } = purchase;

    const storyPriceIsValid = _.isNumber(storyPrice);
    const allowStoryPurchase = storyIsComplete && storyPriceIsValid;

    const { buyPartCopy, supportCtaCopy } = this.getConditionalCopy(
      currentPartIsBlocked,
      allowStoryPurchase,
      blockedMessage,
      isBonusPart,
      isSubscriptionCoinsTestGroup,
      trans
    );
    const hybeStory = isHybeStory(storyId);

    return (
      <div
        className={classnames("story-part-paywall", {
          "is-bonus-part": isBonusPart
        })}
        ref={e => (this.paywallRef = e)}
      >
        <div className="lock-circle">
          <Icon iconName="fa-lock" height="16" color="wp-neutral-2" />
        </div>
        {!isDesktop && <Wallet onWalletClick={onBuyCoins} />}
        <div className="cta">
          <h2>
            {hybeStory && !storyIsComplete
              ? trans("New parts added weekly")
              : !isBonusPart
                ? trans(
                    "Show your support for %s, and continue reading this story",
                    name
                  )
                : trans(
                    "Show your support for %s and stay immersed in the story",
                    name
                  )}
          </h2>
          {this.renderAuthorDetails()}
          <div className="become-a-supporter">{supportCtaCopy}</div>
        </div>
        <div className="purchase-buttons-container">
          {allowStoryPurchase &&
            !isBonusPart && (
              <button
                onClick={() => this.onPurchase(storyPrice, storyId)}
                className={classnames("btn btn-primary unlock unlock-story", {
                  disabled: isPurchasing
                })}
              >
                <span className="cost">
                  <CoinIcon height="24px" width="24px" />
                  <span className="price">{storyPrice}</span>
                </span>
                <span className="unlock-copy">
                  <strong>
                    {isSubscriptionCoinsTestGroup
                      ? trans("Buy whole story")
                      : trans("Unlock whole story")}
                  </strong>
                </span>
              </button>
            )}
          <button
            onClick={() => this.onPurchase(partPrice, storyId, partId)}
            className={classnames("btn btn-primary unlock unlock-part", {
              disabled: isPurchasing
            })}
          >
            <span className="cost">
              <CoinIcon height="24px" width="24px" />
              <span className="price">{partPrice}</span>
            </span>
            <span className="unlock-copy">
              <strong>{buyPartCopy}</strong>
            </span>
          </button>
        </div>
        <div className="more-details">
          {allowStoryPurchase &&
            !isBonusPart && (
              <p
                dangerouslySetInnerHTML={{
                  /* prettier-ignore */ __html: trans("This story has <strong>%s remaining parts</strong>", remainingBlockedParts)
                }}
              />
            )}
          {/* prettier-ignore */ !isBonusPart ? trans("See how Coins support your favorite writers like @%s.", username) : trans("Your coins help writers like @%s earn money for creating the stories you love.", username)}
          <button className="btn-link" onClick={showStoryPaywallModal}>
            {trans("Learn more")}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    purchase: state.purchase,
    amount: state.wallet.amount
  };
}

const mapDispatchToProps = {
  fetchWalletBalance,
  startPaidContentPurchase,
  markPaidContentPurchaseSuccessful,
  finishPaidContentPurchase,
  toggleModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectTrans(StoryPartPaywallUI));

function SuccessToast({ user, trans }) {
  const { name, username, avatar } = user;

  // Copy differs from designs - see MON-645
  return (
    <div style={{ display: "flex" }}>
      <div
        className="avatar avatar-md"
        style={{ flexShrink: 0, marginRight: 12 }}
      >
        <Avatar avatar={avatar} username={username} />
      </div>
      <div style={{ lineHeight: "normal" }}>
        <div style={{ fontSize: 17, fontWeight: 600, marginBottom: 5 }}>
          {name}
        </div>
        <div>
          &ldquo;
          {/* prettier-ignore */ trans("Thank you for the support! I hope you enjoy the story!")}
          &rdquo;
        </div>
      </div>
    </div>
  );
}

SuccessToast.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired
  }),
  trans: PropTypes.func.isRequired
};
