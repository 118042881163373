import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button } from "@wattpad/web-ui-library";

import FormField from "./FormField";
import { useTrans } from "../../hooks";
import { Icon } from "../../shared-components";
import { isClient, sanitizeHTML } from "../../helpers";

const mapLanguagetoBetaLinks = {
  1: "https://support.wattpad.com/hc/en-us/articles/201708720-Becoming-a-Beta-tester", //English
  2: "https://support.wattpad.com/hc/fr/articles/201708720-Devenir-un-b%C3%AAta-testeur", //French
  3: "https://support.wattpad.com/hc/it/articles/201708720-Come-diventare-un-Beta-tester-per-Wattpad", // Italian
  4: "https://support.wattpad.com/hc/de/articles/201708720-Ein-Beta-Tester-werden", //German
  5: "https://support.wattpad.com/hc/es/articles/201708720-Convi%C3%A9rtete-en-un-usuario-Beta", //Spanish
  6: "https://support.wattpad.com/hc/pt/articles/201708720-Como-se-tornar-um-testador-Beta", //Portuguese
  18: "https://support.wattpad.com/hc/fil/articles/201708720-Pagiging-Beta-tester", //Filipino
  20: "https://support.wattpad.com/hc/id/articles/201708720-Menjadi-Beta-tester", //Indonesian
  23: "https://support.wattpad.com/hc/tr/articles/201708720-Beta-Kullan%C4%B1c%C4%B1s%C4%B1-Olma" //Turkish
};

const AccountSettings = props => {
  const { trans } = useTrans();
  const [email, setEmail] = useState(props.email);
  const [isBetaEnabled, setIsBetaEnabled] = useState(props.beta_enabled);

  const { username, has_password, app, verified_email, birthdate } = props;
  const betaLink = mapLanguagetoBetaLinks[app.language]
    ? mapLanguagetoBetaLinks[app.language]
    : mapLanguagetoBetaLinks[1];

  const onCheckboxChange = () => {
    setIsBetaEnabled(oldVal => !oldVal);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const userData = [{ name: "beta_enabled", value: isBetaEnabled }];
    props.onSubmit(event, userData);
  };

  const onUpdateEmail = newEmail => {
    setEmail(newEmail);
  };

  const openSettingsModal = (event, type) => {
    event.preventDefault();
    if (type == "email") {
      props.openEditModal(type, onUpdateEmail, email);
    } else {
      props.openEditModal(type);
    }
  };

  return (
    <div id="account">
      <h2 className="subheading">
        {trans("Change your account information and privacy settings")}
      </h2>
      <form id="account_form" name="account_form" onSubmit={handleSubmit}>
        <div className="row account-wrapper">
          <br />
          <FormField label={trans("Username")}>
            {username}
            <button
              className="info"
              id="changeUsername"
              onClick={event => openSettingsModal(event, "username")}
            >
              {trans("change")}
            </button>
          </FormField>
          <FormField label={trans("Password")}>
            {has_password && (
              <span>
                {sanitizeHTML("********")}
                <button
                  className="info"
                  id="changePassword"
                  onClick={event => openSettingsModal(event, "password")}
                >
                  {trans("change")}
                </button>
              </span>
            )}
            {!has_password && (
              <button
                className="info"
                id="addPassword"
                onClick={event => openSettingsModal(event, "addPassword")}
              >
                {trans("create password")}
              </button>
            )}
          </FormField>
          <FormField label={trans("Email")}>
            {email}
            <button
              className="info"
              id="changeEmail"
              onClick={event => openSettingsModal(event, "email")}
            >
              {trans("change")}
            </button>
            {!verified_email && (
              <div>
                {trans("Not Verified")}
                <a
                  className="info"
                  href={`user_activation?action=send&username=${username}`}
                >
                  {trans("Send Activation Code")}
                </a>
              </div>
            )}
          </FormField>
          <FormField label={trans("Date of Birth")} name="birthdate">
            <div className="birthdate-wrapper">
              <input
                id="birthdate"
                type="date"
                name="birthdate"
                max={moment().format("YYYY-MM-DD")}
                defaultValue={moment(birthdate).format("YYYY-MM-DD")}
              />
              <span id="dob-tooltip" className="help">
                <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
              </span>
            </div>
          </FormField>
          <FormField
            label={trans("Join Beta Program")}
            name="betaEnabled"
            value={isBetaEnabled}
            type="checkbox"
            onCheckboxChange={onCheckboxChange}
          >
            <a
              className="info"
              href={betaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {trans("Learn more")}
            </a>
            <span id="beta-tooltip" className="help">
              <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
            </span>
          </FormField>
          <FormField label={trans("Download your Personal Information")}>
            {/* eslint-disable-next-line wp-lint-rules/valid-href */}
            <a href="/settings/your-wattpad-data">{trans("HTML")}</a>
            {/* eslint-disable-next-line wp-lint-rules/valid-href */}
            <a
              href="/settings/your-wattpad-data?json=1"
              style={{ paddingLeft: "5px" }}
            >
              {trans("JSON")}
            </a>
            <span id="info-tooltip" className="help">
              <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
            </span>
          </FormField>
          <div className="submit">
            <Button label={trans("Submit")} />
          </div>
        </div>
      </form>
      <div className="close-account">
        <a className="on-navigate" href="/user_close">
          {trans("Close Account")}
        </a>
      </div>
    </div>
  );
};

AccountSettings.propTypes = {
  username: PropTypes.string.isRequired,
  has_password: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  language: PropTypes.number.isRequired,
  verified_email: PropTypes.bool,
  birthdate: PropTypes.string,
  beta_enabled: PropTypes.bool.isRequired,
  app: PropTypes.object.isRequired,
  openEditModal: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdateEmail: PropTypes.func
};

if (isClient()) {
  _.extend(AccountSettings.propTypes, {
    openEditModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  });
}

export default AccountSettings;
